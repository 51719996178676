<template>
  <div
    class="industryGraph"
    v-loading.fullscreen.lock="barLoading || graphLoading"
  >
    <div class="industryGraph-content">
      <div class="listBox">
        <div class="sourceItemCrew" v-loading="companyLoading" style="min-height: 30vw">
          <p class="pageTotal">检索到{{ companyPageTotal }}家公司</p>
          <div
            class="sourceItem"
            v-for="(i, index) in compList"
            :key="i.short_name"
            @click="chooseCompany(i)"
          >
            <p class="sourceItem-name">
              {{ (companyParams.page - 1) * 10 + index + 1 }}.
              {{ i.short_name != "" ? i.short_name : i.full_name }}
              <span class="tagBlue" slot="reference" v-if="i.board_id != 9">{{
                boards_map[i.board_id - 1]
              }}</span>
              <el-popover
                placement="right-start"
                width="200"
                trigger="hover"
                :content="i.industry1"
              >
                <span class="tagBlue" slot="reference" v-if="i.industry1">{{
                  i.industry1 | textCut(5)
                }}</span>
              </el-popover>
            </p>
          </div>
          <div class="contentFooter">
          <el-pagination
            background
            small
            layout="prev, pager, next"
            :pager-count="5"
            :current-page="companyParams.page"
            :page-size="companyParams.page_size"
            :total="companyPageTotal"
            @current-change="handlePageChangeCompany"
          >
          </el-pagination>
        </div>
        </div>
        <!-- <div class="contentFooter">
          <el-pagination
            background
            small
            layout="prev, pager, next"
            :pager-count="5"
            :current-page="companyParams.page"
            :page-size="companyParams.page_size"
            :total="companyPageTotal"
            @current-change="handlePageChangeCompany"
          >
          </el-pagination>
        </div> -->
      </div>
      <div class="graphBox">
        <el-button
          class="graphBox-btn"
          type="primary"
          size="mini"
          v-if="level == 1"
          @click="back"
          >返回</el-button
        >
        <!-- <industry-graph-echarts-total
          v-if="level == 0"
          :apiDataGraphChart="chartDataLinkTotal"
        >
        </industry-graph-echarts-total> -->
        <!-- <industry-graph-echarts
        v-if="level==1"
          :apiDataGraphChart="chartDataLink"
        ></industry-graph-echarts> -->
        <graph-total
          v-if="level == 0"
          :nodes="nodesTotal"
          :links="linksTotal"
          :eheight="800"
          :longEdge="true"
          style="width: 100%;height:200vh"
        ></graph-total>
        <graph
          v-if="level == 1"
          :nodes="nodes"
          :links="links"
          :eheight="800"
          :longEdge="true"
          style="width: 100%"
          :style="hasBigNode ? 'height:770vh' : 'height:220vh'"
        ></graph>
      </div>
    </div>
    <el-dialog title="公司信息" width="70%" :visible.sync="showInfo">
      <p
        style="
          margin-left: 20px;
          margin-bottom: 18px;
          font-size: 16px;
          font-weight: bold;
          color: #337ab7;
        "
      >
        {{companyInfo.short_name}}<el-tag
          size="mini"
          type="primary"
          style="font-weight: normal; margin-left: 10px"
          v-if="companyInfo.board_id && companyInfo.board_id != 9"
          >{{ boards_map[companyInfo.board_id-1] }}</el-tag
        ><el-tag
          size="mini"
          type="primary"
          style="font-weight: normal; margin-left: 10px"
          v-if="companyInfo.industry1"
          >{{ companyInfo.industry1 }}</el-tag
        >
        <el-button v-if="companyInfo.code" size="mini" type="primary" style="margin-right: 15px;float: right" @click="toCompanyGraph">跳转至企业图谱</el-button>
      </p>
      <el-form label-position="right" label-width="100px" :model="companyInfo">
        <el-form-item label="公司全称" size="small" style="width: 60%">
          <el-input v-model="companyInfo.full_name"></el-input>
        </el-form-item>
        <el-form-item label="注册地址" size="small" style="width: 60%">
          <el-input v-model="companyInfo.address"></el-input>
        </el-form-item>
        <div style="display: flex" v-if="companyInfo.code">
          <el-form-item label="股票代码" size="small" style="width: 40%">
            <el-input v-model="companyInfo.code"></el-input>
          </el-form-item>
          <el-form-item label="上市日期" size="small" style="width: 40%">
            <el-input v-model="companyInfo.market_time"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="法定代表人" size="small" style="width: 40%">
            <el-input v-model="companyInfo.representative"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" size="small" style="width: 40%">
            <el-input v-model="companyInfo.telephone"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="邮箱" size="small" style="width: 40%">
            <el-input v-model="companyInfo.email"></el-input>
          </el-form-item>
          <el-form-item label="纳税识别号" size="small" style="width: 40%">
            <el-input v-model="companyInfo.taxpayer_num"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="参保人数" size="small" style="width: 40%">
            <el-input v-model="companyInfo.insured_num"></el-input>
          </el-form-item>
          <el-form-item label="公司类型" size="small" style="width: 40%">
            <el-input v-model="companyInfo.enterprises_type"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus.js";
import IndustryBarVertical from "../components/IndustryBarVertical.vue";
import industryGraphEchartsTotal from "../components/IndustryGraphEchartsTotal.vue";
import IndustryGraphEcharts from "../components/IndustryGraphEcharts.vue";
import Graph from "../components/Graph.vue";
import GraphTotal from "../components/GraphTotal.vue";

export default {
  name: "IndustryGraph",
  components: {
    industryGraphEchartsTotal,
    IndustryGraphEcharts,
    Graph,
    GraphTotal,
    IndustryBarVertical,
  },
  data() {
    return {
      showInfo: false,
      companyLoading: false,
      barLoading: false,
      graphLoading: false,
      permissions: [],
      hasBigNode: false,
      apiDataBarChart: {
        legend: [],
        data: [],
      },
      boards_map: [
        "深圳主板",
        "上海主板",
        "深圳中小板",
        "深圳创业板",
        "上海科创板",
        "新三板",
      ],
      graphIndustry: {
        lv1_industry: [],
        lv2_industry: [],
        lv3_industry: [],
        lv4_industry: [],
        lv5_industry: [],
        lv6_industry: [],
      },
      level: 0,
      industry_id: "",
      nodes: [],
      links: [],
      nodesTotal: [],
      linksTotal: [],
      boards_map: [
        "深圳主板",
        "上海主板",
        "深圳中小板",
        "深圳创业板",
        "上海科创板",
        "新三板",
      ],
      companyInfo: {
        id: "",
        full_name: "",
        short_name: "",
        code: "",
        market_time: "",
        founded_time: "",
        address: "",
        representative: "",
        telephone: "",
        email: "",
        taxpayer_num: "",
        insured_num: "",
        enterprises_type: "",
        industry1: ""
      },
      compList: [],
      companyPageTotal: 0,
      companyParams: {
        permissions: "",
        industry_node: "",
        industry_level: "",
        page: 1,
        page_size: 10,
      },
      chartDataLinkTotal: {},
      chartDataLink: {},
    };
  },
  mounted() {
    cookie.setCookie("serverValue", "行业图谱");
    this.permissions = cookie.getCookie("permissions");
    // this.getIndustryCount();
    this.listenReset();
    this.listenIndustryGraph();
    this.listenCompanyPageTotal();
    this.listenIndustryId();
    this.getIndustryGraph();
    this.getIndustryGraphCompany();
  },
  beforeDestroy() {
    EventBus.$off("industryId");
    EventBus.$off("compList");
    EventBus.$off("companyPageTotal");
    EventBus.$off("reset");
  },
  methods: {
    // 获取行业图谱公司列表
    getIndustryGraphCompany() {
      console.log("-------------------获取行业图谱公司列表")
      this.companyParams.permissions = this.permissions;
      console.log("companyParams", this.companyParams);
      this.companyLoading = true;
      // this.compList = []
      api
        .getIndustryGraphCompany(this.companyParams)
        .then((res) => {
          console.log("行业图谱公司列表", res);
          // this.compList = res.data;
          // res.data.forEach(i => {
          //   if (i.industry1.indexOf("[") != -1) {
          //     // print("JSON.parse", JSON.parse(i))
          //     i.industry1 = JSON.parse(i.industry1).join("，")
          //   }
          //   // this.compList.push(i)
          // })
          this.compList = res.data;
          console.log("compList", this.compList)
          this.companyPageTotal = res.total;
          this.companyLoading = false;
        })
        .catch((err) => {
          console.log(err)
          this.companyLoading = false;
        });
    },
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // 对象数组查找重复
    findDup(arr, attr) {
      arr.forEach((a) => {
        const index = arr.findIndex((b) => b[attr] == a[attr]);
        arr.splice(index, 1);
      });
      console.log("arr", arr);
    },
    // 选择公司
    chooseCompany(company) {
      console.log("company_id", company);
      this.companyInfo = company
      // if (this.companyInfo.industry1.indexOf("[") != -1) {
      //   this.companyInfo.industry1 = JSON.parse(this.companyInfo.industry1).join("，")
      // }
      this.showInfo = true;
      // this.$router.push("/company_graph");
      // this.$nextTick(() => {
      //   EventBus.$emit("short_name", company.short_name);
      //   EventBus.$emit("company_id", company.company_id);
      //   EventBus.$emit("company_id_selected", company.company_id);
      // });
    },
    // 跳转企业图谱
    toCompanyGraph() {
      this.$router.push("/company_graph");
      this.$nextTick(() => {
        EventBus.$emit("short_name", this.companyInfo.short_name);
        EventBus.$emit("company_id", this.companyInfo.id);
        EventBus.$emit("company_id_selected", this.companyInfo.id);
      });
    },
    // 返回
    back() {
      let graph_data = {
        industry_id: "",
        industry_level: 0,
      };
      EventBus.$emit("industry_graph", graph_data);
      this.level = 0;
      this.industry_id = "";
      this.graphIndustry = {
        lv1_industry: [],
        lv2_industry: [],
        lv3_industry: [],
        lv4_industry: [],
        lv5_industry: [],
        lv6_industry: [],
      };
      this.nodes = [];
      this.links = [];
      this.getIndustryGraph();
    },
    // 判断行业健康程度
    checkHP(num) {
      if (num < 1) {
        return "low";
      } else if (num >= 1 && num <= 10) {
        return "medium";
      } else {
        return "high";
      }
    },
    listenIndustryGraph() {
      EventBus.$on("industry_graph", (data) => {
        this.hasBigNode = false;
        console.log("监听industry_graph", data);
        this.companyParams.page = 1;
        this.companyParams.industry_node = data.industry_id;
        this.companyParams.industry_level = data.industry_level;
        this.getIndustryGraphCompany();
        if (data.industry_level == 1) {
          this.graphIndustry["lv1_industry"].push({
            father_id: 0,
            id: data.industry_id,
            name: data.industry_node,
            level: 1,
            num: data.num,
          });
        }
        if (data.industry_level > 1) {
          this.level = 1;
          this.industry_id = data.industry_id;
          console.log("click_id", data.industry_id);
          console.log("click_level", data.industry_level);
          if (
            this.graphIndustry[`lv${data.industry_level + 1}_industry`].length >
            0
          ) {
            this.nodes = [];
            this.links = [];
            console.log(
              "father_id",
              this.graphIndustry[`lv${data.industry_level + 1}_industry`][0]
                .father_id
            );
            Object.keys(this.graphIndustry).forEach((g) => {
              if (
                parseInt(g.replace("lv", "").replace("_industry", "")) >
                data.industry_level
              ) {
                console.log(
                  "lvlvlv",
                  parseInt(g.replace("lv", "").replace("_industry", ""))
                );
                this.graphIndustry[g] = [];
              }
            });
            // this.graphIndustry[`lv${data.industry_level+1}_industry`] = []
            console.log("-----------graphIndustry", this.graphIndustry);
            // this.drawIndustryGraph()
          }
          // else {
          //   this.getIndustryGraph();
          // }
          this.getIndustryGraph();
        }
      });
    },
    // 渲染行业图谱
    async drawIndustryGraph() {
      let startTime = (new Date()).valueOf()
      Object.keys(this.graphIndustry).forEach((k) => {
        if (this.graphIndustry[k].length > 36) {
          this.hasBigNode = true;
        }
      });
      this.graphIndustry.lv1_industry.forEach((i) => {
        this.nodes.push({
          industry_id: i.id,
          name: i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name,
          full_name: i.name,
          level: 1,
          level_independence: this.checkHP(i.num),
          num: i.num,
        });
      });
      let lv2_map = {};
      this.graphIndustry.lv2_industry.forEach((i) => {
        this.nodes.push({
          industry_id: i.id,
          name:
            i.name.length > 4
              ? i.name.substring(0, 4) + " " + "..."
              : i.name + " ", // name不能重复，二级加1个空格
          full_name: i.name,
          level: 2,
          level_independence: this.checkHP(i.num),
          num: i.num,
        });
        lv2_map[i.id] =
          i.name.length > 4
            ? i.name.substring(0, 4) + " " + "..."
            : i.name + " ";
      });
      let lv3_map = {};
      this.graphIndustry.lv3_industry.forEach((i) => {
        this.nodes.push({
          industry_id: i.id,
          name:
            i.name.length > 4
              ? i.name.substring(0, 4) + "  " + "..."
              : i.name + "  ", // name不能重复，三级加2个空格
          full_name: i.name,
          level: 3,
          level_independence: this.checkHP(i.num),
          father_name: lv2_map[i.father_id],
          num: i.num,
        });
        lv3_map[i.id] =
          i.name.length > 4
            ? i.name.substring(0, 4) + "  " + "..."
            : i.name + "  ";
      });
      let lv4_map = {};
      this.graphIndustry.lv4_industry.forEach((i) => {
        this.nodes.push({
          industry_id: i.id,
          name:
            i.name.length > 4
              ? i.name.substring(0, 4) + "   " + "..."
              : i.name + "   ", // name不能重复，4级加3个空格
          full_name: i.name,
          level: 4,
          level_independence: this.checkHP(i.num),
          father_name: lv3_map[i.father_id],
          num: i.num,
        });
        lv4_map[i.id] =
          i.name.length > 4
            ? i.name.substring(0, 4) + "   " + "..."
            : i.name + "   ";
      });
      let lv5_map = {};
      this.graphIndustry.lv5_industry.forEach((i) => {
        this.nodes.push({
          industry_id: i.id,
          name:
            i.name.length > 4
              ? i.name.substring(0, 4) + "    " + "..."
              : i.name + "    ", // name不能重复，5级加4个空格
          full_name: i.name,
          level: 5,
          level_independence: this.checkHP(i.num),
          father_name: lv4_map[i.father_id],
          num: i.num,
        });
        lv5_map[i.id] =
          i.name.length > 4
            ? i.name.substring(0, 4) + "    " + "..."
            : i.name + "    ";
      });
      this.graphIndustry.lv6_industry.forEach((i) => {
        this.nodes.push({
          industry_id: i.id,
          name:
            i.name.length > 4
              ? i.name.substring(0, 4) + "     " + "..."
              : i.name + "     ", // name不能重复，6级加5个空格
          full_name: i.name,
          level: 6,
          level_independence: this.checkHP(i.num),
          father_name: lv5_map[i.father_id],
          num: i.num,
        });
      });
      this.nodes = await this.unique(
        JSON.parse(JSON.stringify(this.nodes)),
        "name"
      );
      // this.findDup(JSON.parse(JSON.stringify(this.nodes)), "name")
      this.nodes.forEach((i) => {
        if (i.level == 2) {
          this.links.push({
            source: this.nodes[0].name,
            target: i.name,
          });
        }
        if (i.level == 3 || i.level == 4 || i.level == 5 || i.level == 6) {
          this.links.push({
            source: i.father_name,
            target: i.name,
          });
        }
      });
      console.log("！！！！nodes", this.nodes);
      console.log("！！！！links", this.links);
      let endTime = (new Date()).valueOf()
        console.log(
          `%c装填节点：%c${(endTime-startTime) / 1000}`,
          "color: blue;font-weight:bold",
          "color: orange;font-weight:bold"
        )
    },
    // 获取行业图谱
    getIndustryGraph() {
      this.graphLoading = true;
      let params = {
        industry_id: this.industry_id,
        level: this.level,
        permissions: this.permissions,
        // industry_name: this.companyParams.industry_node
      };
      let startTime = (new Date()).valueOf()
      api.getIndustryGraph(params).then(async (res) => {
        let endTime = (new Date()).valueOf()
        console.log(
          `%c接口时间：%c${(endTime-startTime) / 1000}`,
          "color: blue;font-weight:bold",
          "color: orange;font-weight:bold"
        )
        if (this.level == 1) {
          let startTime2 = (new Date()).valueOf()
          // this.chartDataLink = this.graphIndustry;
          // this.nodes = nodes_test.node
          // this.links = nodes_test.edge
          // console.log("nodes.node", nodes_test.node)
          // console.log("nodes.edge", nodes_test.edge)
          if (this.graphIndustry.lv1_industry.length == 0) {
            this.graphIndustry.lv1_industry = await res.data.lv1_industry;
          }
          if (this.graphIndustry.lv2_industry.length == 0) {
            this.graphIndustry.lv2_industry = await res.data.lv2_industry;
          }
          if (this.graphIndustry.lv3_industry.length == 0) {
            this.graphIndustry.lv3_industry = await res.data.lv3_industry;
          }
          if (this.graphIndustry.lv4_industry.length == 0) {
            this.graphIndustry.lv4_industry = await res.data.lv4_industry;
          }
          if (this.graphIndustry.lv5_industry.length == 0) {
            this.graphIndustry.lv5_industry = await res.data.lv5_industry;
          }
          if (this.graphIndustry.lv6_industry.length == 0) {
            this.graphIndustry.lv6_industry = await res.data.lv6_industry;
          }
          // Object.keys(this.graphIndustry).forEach(k => {
          //   this.graphIndustry[k] = this.unique(this.graphIndustry[k], "name")
          // })
          console.log("graphIndustry", this.graphIndustry);
          let endTime2 = (new Date()).valueOf()
          console.log(
            `%c赋值时间：%c${(endTime2-startTime2) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          )
          // this.nodes = [];
          // this.links = [];
          setTimeout(() => {
            this.drawIndustryGraph();
            this.graphLoading = false;
          }, 100);
        } else if (this.level == 0) {
          EventBus.$emit("industry_composition", res.data.lv1_industry)
          // this.chartDataLinkTotal = res.data;
          this.nodesTotal = [];
          this.linksTotal = [];
          res.data.lv0_industry.forEach((i) => {
            this.nodesTotal.push({
              name: i.name,
              level: 1,
              level_independence: this.checkHP(i.num),
              num: i.num,
            });
          });
          res.data.lv1_industry.forEach((i) => {
            this.nodesTotal.push({
              industry_id: i.id,
              name: i.name,
              level: 2,
              level_independence: this.checkHP(i.num),
              num: i.num,
            });
          });
          this.nodesTotal.forEach((i) => {
            if (i.level == 2) {
              this.linksTotal.push({
                source: this.nodesTotal[0].name,
                target: i.name,
              });
            }
          });
          console.log("linksTotal", this.linksTotal);
          console.log("nodesTotal", this.nodesTotal);
          this.graphLoading = false;
        }
      });
    },
    // 翻页
    handlePageChangeCompany(val) {
      // EventBus.$emit("pageChange", val);
      this.companyParams.page = val;
      this.getIndustryGraphCompany();
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", (data) => {
        this.level = 0;
        this.companyParams.page = 1
        this.getIndustryGraph();
        this.getIndustryGraphCompany()
      });
    },
    // 监听选择产业
    listenIndustryId() {
      EventBus.$on("industryId", (data) => {
        this.industry_id = data;
        this.level = 1;
        this.getIndustryGraph();
      });
    },
    listenCompanyPageTotal() {
      EventBus.$on("companyPageTotal", (data) => {
        this.companyPageTotal = data.total;
        this.companyParams = data.params;
      });
    },
    // // 获取行业构成分布
    // getIndustryCount() {
    //   let params = {
    //     // permissions: sessionStorage.getItem("permissions"),
    //     permissions: cookie.getCookie("permissions"),
    //   };
    //   this.barLoading = true;
    //   this.apiDataBarChart.legend = [];
    //   this.apiDataBarChart.data = [];
    //   api.getIndustryCount(params).then((res) => {
    //     console.log("行业统计", res);
    //     // this.apiDataPieChart = res.data;
    //     for (let i = res.data.length - 1; i >= 0; i--) {
    //       let name = "";
    //       if (res.data[i].name.length > 5) {
    //         name = res.data[i].name.substring(0, 5) + "...";
    //       } else {
    //         name = res.data[i].name;
    //       }
    //       this.apiDataBarChart.legend.push(name);
    //       this.apiDataBarChart.data.push(res.data[i].value);
    //     }
    //     this.barLoading = false;
    //   });
    // },
  },
};
</script>

<style lang="scss">
.industryGraph {
  // margin-top: 52px;
  &-content {
    width: 100%;
    margin-left: 0px;
    margin-top: 5px;
    // background-color: white;
    // padding-left: 10px;
    // border-radius: 6px;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    display: flex;
    flex-direction: row-reverse;
    min-height: 70vh;
  }
  .listBox {
    width: 20vw;
    // min-height: 20vw;
    background-color: white;
    padding-left: 10px;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    flex-shrink: 0;
  }
  .graphBox {
    position: relative;
    width: 57vw;
    // min-height: 25vw;
    margin-right: 0.5%;
    background-color: white;
    padding-left: 10px;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    &-btn {
      position: absolute;
      right: 10px;
      z-index: 20;
    }
  }
}
</style>
