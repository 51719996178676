<template>
  <div class="graph">
    <div ref="graph" id="graph" class="graph" :style="{ height: '100%' }"></div>
  </div>
</template>

<script>
import { EventBus } from '../utils/eventBus';
export default {
  name: "graph",
  props: ["eheight", "nodes", "links", "longEdge"],
  data() {
    return {
      graph: "",
      width: 0
    };
  },
  computed: {
    findSource() {
      return this.$store.getters.getFindSource;
    },
  },
  watch: {
    links: {
      handler(newValue, oldValue) {
        if (this.graph) {
          this.graph.off("click");
          echarts.init(this.$refs["graph"]).dispose()
        }
        this.$nextTick(() => {
          this.drawGraph();
        })
        // this.drawGraph();
        // echarts.init(this.$refs["graph"]).resize();
      },
      deep: true,
    },
  },
  mounted() {
    // this.drawGraph();
    this.width = document.getElementById("graph").clientWidth
  },
  methods: {
    async drawGraph() {
      console.log("drawGraph");
      // let itemStyleNormal = {
      //   normal: {
      //     // color: "#4592FF",
      //     color: "#333333",
      //     borderColor: "#82dffe",
      //     borderWidth: 3,
      //     shadowBlur: 8,
      //     shadowColor: "#82dffe"
      //   }
      // };
      let itemStyleNormalSelected = {
        normal: {
          // color: "#4592FF",
          color: "#7EB450",
          borderColor: "yellow",
          borderWidth: 5,
          symbolSize: 60
          // borderType: "dashed",
          // borderDashOffset: 30
          // shadowBlur: 3,
          // shadowColor: "#03fc62",
        },
      };
      let itemStyleNormal = {
        normal: {
          // color: "#4592FF",
          color: "#7EB450",
          // borderColor: "#03fc62",
          // borderWidth: 3,
          // shadowBlur: 3,
          // shadowColor: "#03fc62",
        },
      };
      let itemStyleLowSelected = {
        normal: {
          // color: "#4592FF",
          color: "#f06467",
          borderColor: "yellow",
          borderWidth: 5,
          symbolSize: 60
          // borderType: "dashed",
          // borderDashOffset: 30
          // shadowBlur: 3,
          // shadowColor: "#03fc62",
        },
      };
      let itemStyleLow = {
        normal: {
          // color: "#4592FF",
          color: "#f06467",
          // borderColor: "#f06467",
          // borderWidth: 3,
          // shadowBlur: 3,
          // shadowColor: "#f06467",
        },
      };
      let itemStyleMediumSelected = {
        normal: {
          // color: "#4592FF",
          color: "#orange",
          borderColor: "yellow",
          borderWidth: 5,
          symbolSize: 60
          // borderType: "dashed",
          // borderDashOffset: 30
          // shadowBlur: 3,
          // shadowColor: "#03fc62",
        },
      };
      let itemStyleMedium = {
        normal: {
          // color: "#4592FF",
          color: "orange",
          // borderColor: "#82dffe",
          // borderWidth: 3,
          // shadowBlur: 3,
          // shadowColor: "yellow",
        },
      };
      let h = this.$refs["graph"].clientHeight;

      let count = this.nodes.filter((x) => x.level == 2).length;
      let temp = 1;
      // let image = await this.getImgData(
      //   "http://surbot.cn:7713/media/images/ventilo_img.jpg/"
      // );
      // let image = await this.getImgData(
      //   `http://surbot.cn:7713/media/images/${this.nodes[0].image}`
      // );
      // console.log(">>>>>>>>nodes", this.nodes);
      // console.log(">>>>>>>>links", this.links);
      let sourceList = new Set();
      this.links.forEach((i) => {
        sourceList.add(i.source);
      });
      let sourceArr = [...sourceList];
      // console.log("sourceArr", sourceArr);
      // 节点统计（这一级节点和之前全部节点的数量总和）
      let nodeCount = {
        lv1: 0,
        lv2: 0,
        lv3: 0,
        lv4: 0,
        lv5: 0,
        lv6: 0,
      }
      // 节点统计（各级节点的数量）
      let nodeLvCount = [0,0,0,0,0,0]
      this.nodes.forEach(n => {
        Object.keys(nodeCount).forEach(o => {
          if (n.level <= parseInt(o.replace("lv", ""))) {
            nodeCount[o] += 1
          }
          if (n.level == parseInt(o.replace("lv", ""))) {
            nodeLvCount[n.level-1] += 1
          }
        })
      })
      console.log("nodeCount", nodeCount)
      console.log("nodeLvCount", nodeLvCount)
      for (let index = 0; index < this.nodes.length; index++) {
        this.nodes[index].category = this.nodes[index].level - 1;
        if (this.nodes[index].level_independence == "high") {
          this.nodes[index].itemStyle = itemStyleNormal;
        } else if (this.nodes[index].level_independence == "medium") {
          this.nodes[index].itemStyle = itemStyleMedium;
        } else {
          this.nodes[index].itemStyle = itemStyleLow;
        }
        if (this.nodes[index].level == 1) {
          //设置第一层节点的位置
          // this.nodes[index].itemStyle.normal.borderColor = "#04f2a7";
          // this.nodes[index].itemStyle.normal.borderWidth = 4;
          this.nodes[index].x = 0;
          this.nodes[index].y = 100;
          // this.nodes[index].y = index * 50;

          // this.nodes[index].symbol = "image://" + image; //设置第一个节点的图片
        // } else if (this.nodes[index].level == 2) {
        //   this.nodes[index].x = 100 * this.nodes[index].level
        //   this.nodes[index].y = 50 + (index-nodeCount.lv1) * 20
        //   console.log("lv2", this.nodes[index].x, "   ", this.nodes[index].y)
        // } else if (this.nodes[index].level == 3) {
        //   this.nodes[index].x = 100 * this.nodes[index].level
        //   this.nodes[index].y = 50 + (index-nodeCount.lv2) * 20
        //   console.log("lv3", this.nodes[index].x, "   ",  this.nodes[index].y)
        } else {
          let hasBigNode = nodeLvCount.some((x) => {
            return x > 10
          })
          let grade = 0
          for(let i=nodeLvCount.length-1;i >= 0;i--) {
            if (nodeLvCount[i] != 0) {
              grade = i + 1
              break
            }
          }
          // console.log("grade", grade)
          let gap = this.width / (grade-1)
          if (grade == 2) {
            gap = gap / 3
          }
          if (grade == 3) {
            gap = gap / 2
          }
          // console.log("gap", gap)
          // if (hasBigNode) {
          //   this.nodes[index].x = gap * this.nodes[index].level
          //   this.nodes[index].y = 50 + (index-nodeCount[`lv${this.nodes[index].level-1}`]) *165
          // } else {
          this.nodes[index].x = gap * this.nodes[index].level
          this.nodes[index].y = 50 + (index-nodeCount[`lv${this.nodes[index].level-1}`]) * 65
          // }
          
          
          // } else {
          //   this.nodes[index].y = 50 + (index-nodeCount[`lv${this.nodes[index].level-1}`]) * 50
          // }
          
        }
        
        // else  {
        //   //设置第二层节点的位置
        //   // let level2Count = 0;
        //   // this.nodes.forEach((i) => {
        //   //   if (i.level == 2) {
        //   //     level2Count++;
        //   //   }
        //   // });
        //   // if (level2Count > 10) {
        //   //   this.nodes[index].x = 1000 * this.nodes[index].level;
        //   // } else {
        //     this.nodes[index].x = 100 * this.nodes[index].level
        //   // }
          
        //   // this.nodes[index].y = index * 50;
        //   this.nodes[index].y = h / 2 + index * 10;
        //   console.log("index", index)
        //   // if (count % 2 == 0) {
        //   //   //在上一级节点的上方
        //   //   if (temp <= count / 2) {
        //   //     this.nodes[index].y = h / 2 - 100 - temp * 340;
        //   //   } else {
        //   //     this.nodes[index].y = h / 2 - 100 + (temp - count / 2) * 340;
        //   //   }
        //   // } else {
        //   //   //上一级节点的下方
        //   //   if (temp < count / 2) {
        //   //     this.nodes[index].y = h / 2 - 100 - temp * 340;
        //   //   } else if (temp == parseInt(count / 2 + 1)) {
        //   //     this.nodes[index].y = h / 2 - 100;
        //   //   } else {
        //   //     this.nodes[index].y =
        //   //       h / 2 - 100 + (temp - parseInt(count / 2) + 1) * 340;
        //   //   }
        //   // }

        //   // temp++;
        // }
      }

      for (let index = 0; index < this.links.length; index++) {
        this.links[index].value = this.links[index].type;
      }
      this.graph = echarts.init(this.$refs["graph"]);
      let that = this;

      this.graph.on("click", function (params) {
        //点击判断是否展开和收缩
        if (params.dataType!='node') {
          return
        }
        console.log("paramsGRAPH", params);
        if (params.dataIndex != 0) {
          let graph_data = {
            industry_node: params.data.full_name.replace(/ /g, ""),
            industry_level: params.data.level,
            industry_id: params.data.industry_id
          }
          EventBus.$emit("industry_graph", graph_data)
        }
        
        if (params.data.level !== 2) {
          that.$emit("list", params.name);
          that.$emit("node", params.data);
        }

        let length = that.nodes.filter(
          (x) => parseInt(params.data.level) + 1 == x.level
        );

        // let array = that.links.filter((x) => params.name == x.source);
        let array = that.links.filter((x) => params.name == x.source);
        if (!params.data.type) {
          //根据关系过滤出想要的节点
          // let option = that.graph.getOption();
          // let temp = 1;
          // let arr = [];
          // for (let index = 0; index < array.length; index++) {
          //   let node = that.nodes.filter((x) => x.name == array[index].target);

          //   if (node.length > 0) {
          //     temp++;
          //     arr.push(node[0]);
          //   }
          // }
          // arr = Array.from(new Set(arr));
          // console.log("arr", arr)
          // for (let index = 0; index < arr.length; index++) {
          //   //设置过滤出来节点的位置
          //   if (arr.length > 10) {
          //     arr[index].x = params.data.x + 1200;
          //   } else {
          //     arr[index].x = that.longEdge
          //       ? params.data.x + 1500
          //       : params.data.x + 300;
          //   }
          //   arr[index].y = index * 200;
          // }

          // if (params.data.level == 1) {
          //   //展开和收缩的主要判断
          //   // option.series[0].itemStyle.color = "white";
          //   // console.log(option.series[0]);
          //   option.series[0].data = that.nodes
          // } else if (arr.length == 0) {
          //   option.series[0].data = option.series[0].data.filter(
          //     (x) => x.level <= params.data.level
          //   );
          // } else if (length.length == 0) {
          //   option.series[0].data = option.series[0].data.concat(arr);
          // } else if (
          //   option.series[0].data
          //     .filter((x) => x.level <= params.data.level)
          //     .concat(arr).length == option.series[0].data.length
          // ) {
          //   option.series[0].data = option.series[0].data.filter(
          //     (x) => x.level <= params.data.level
          //   );
          // } else if (
          //   option.series[0].data
          //     .filter((x) => x.level <= params.data.level)
          //     .concat(arr).length !== option.series[0].data.length
          // ) {
          //   option.series[0].data = option.series[0].data
          //     .filter((x) => x.level <= params.data.level)
          //     .concat(arr);
          // }
          // if (params.data.level == "2") {
          //   option.series[0].itemStyle.color = "white";
          //   console.log(option.series[0]);
          // }
          option.series[0].data.forEach((i) => {
            // i.itemStyle = itemStyleNormal;
            if (i.level_independence == "high") {
              if (i.name == params.data.name) {
                i.itemStyle = itemStyleNormalSelected;
              } else {
                i.itemStyle = itemStyleNormal;
              }
            } else if (i.level_independence == "medium") {
              if (i.name == params.data.name) {
                i.itemStyle = itemStyleMediumSelected;
              } else {
                i.itemStyle = itemStyleMedium;
              }
            } else {
              if (i.name == params.data.name) {
                i.itemStyle = itemStyleLowSelected;
              } else {
                i.itemStyle = itemStyleLow;
              }
            }
          });

          setTimeout(() => {
            that.graph.setOption(option, true);
            // console.log("option", option);
          }, 500);
        }
      });
      let option = {
        tooltip: {
          // formatter: "呼吸机的知识图谱"
          formatter: function (params) {
            let level = "";
            if (params.data.level_independence == "low") {
              level = "低";
            } else if (params.data.level_independence == "medium") {
              level = "中";
            } else if (params.data.level_independence == "high") {
              level = "高";
            }
            if (level) {
              return params.data.full_name + "<br/>" + "行业健康度：" + level;
            } else {
              return "";
            }
          },
        },

        label: {
          // position: "bottom",
          x: "left",
          color: "#333333",
          fontWeight: "bold",
          normal: {
            show: true,
            textStyle: {
              fontSize: 12,
            },
          },
        },

        series: [
          {
            type: "graph",
            left: "center",
            top: "55",
            // focusNodeAdjacency: true,
            roam: true,
            // roam: false,
            // nodeScaleRatio: 0.6,
            // right: "auto", //组件离容器右侧的距离,百分比字符串或整型数字
            bottom: "auto", //组件离容器下侧的距离,百分比字符串或整型数字
            width: "auto", //图例宽度
            height: "auto",
            layout: "none",
            symbolSize: 35,
            itemStyle: {
              normal: {
                // shadowBlur: 10,
                // shadowColor: "white",
                // backgroundColor: "transparent"
              },
            },
            categories: [
              {
                symbolSize: 60,
                itemStyle: {
                  normal: {
                    color: "#333333",
                  },
                },
              },
              {
                itemStyle: {
                  normal: {
                    // color: "#4592FF",
                    // color: "#333333",
                    color: "#333333",
                    // borderColor: "#82dffe",
                    // borderWidth: 3,
                    // shadowBlur: 8,
                    // shadowColor: "#04f2a7"
                  },
                },
              },
            ],
            label: {
              normal: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
        //         formatter: [
        // '{b}',
        // '{数量：@num}'
        //     ].join('\n'),
        formatter: function(params) {
          if (params.data.num) {
            return params.data.name + " \t" + params.data.num
          } else {
            return params.data.name
          }
        }
              },
              
            },

            edgeSymbolSize: [4, 10],
            edgeSymbol: ["none", "arrow"],
            edgeLabel: {
              silent: true,
              normal: {
                show: false,

                rich: {
                  c: {
                    height: 20,
                    width: 40,
                    backgroundColor: "gray",
                    lineHeight: 0,
                    borderRadius: 8,
                    color: "#fff",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },

                textStyle: {
                  fontSize: 14,
                  color: "#000",
                },
                formatter: "{c|{c}}",
              },
            },

            data: this.nodes,
            links: this.links,
            lineStyle: {
              silent: true,
              normal: {
                opacity: 1,
                width: 1,
                curveness: 0.1,
              },
              emphasis: {
                color: "orange",
              },
            },
          },
        ],
      };

      this.graph.setOption(option);
      this.graph.resize();
      window.addEventListener("resize", function () {
        this.graph.resize();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.graph {
  width: 100%;
  height: 100%;
}
</style>