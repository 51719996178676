<template>
  <div class="industryGraphEchartsTotal">
    <div class="graphContainer">
      <div class="graphChart" ref="graphChart"></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../utils/eventBus';
export default {
  name: "industryGraphEchartsTotal",
  props: {
    apiDataGraphChart: Object,
  },
  data() {
    return {
      graphChart: "",
      graphData: [],
      graphLink: [],
    };
  },
  watch: {
    apiDataGraphChart: {
      async handler(newVal, oldVal) {
        // 生成节点
        let graphData = [];
        newVal.lv0_industry.forEach((i) => {
          graphData.push({
            name: i.name,
            category: 0,
          });
        });
        newVal.lv1_industry.forEach((i) => {
          graphData.push({
            name: i.name +" ",
            category: 1,
          });
        });
        // console.log("graphData", graphData);
        // 生成关系
        let graphLink = [];
        graphData.forEach((i) => {
          if (i.category == 1) {
            graphLink.push({
              source: graphData[0].name,
              target: i.name,
              value: "",
            });
          }
        });
        // console.log("graphLink", graphLink);
        this.graphData = await graphData;
        this.graphLink = await graphLink;
        this.setGraphChart();
      },
    },
  },
  methods: {
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // echart初始化
    setOriginGraphChart() {
      echarts.init(this.$refs.graphChart).dispose();
      this.graphChart = echarts.init(this.$refs.graphChart);
      this.graphChart.on("click", function(params) {
        console.log("-------", params)
        if (params.dataIndex != 0) {
          EventBus.$emit("industryId", params.dataIndex)
        }
        let graph_data = {
            industry_node: params.data.name,
            industry_level: params.data.category
          }
          EventBus.$emit("industry_graph", graph_data)
      })
    },
    // 渲染echart
    setGraphChart() {
      let option = {
        legend: {
          x: "left",
          data: ["全部行业", "一级分类"],
          // selected: {
          //   三级分类: false,
          // },
          textStyle: {
            fontSize: 13, //字体大小
            color: "#333333", //字体颜色
          },
        },
        animation: false,
        series: [
          {
            type: "graph",
            layout: "force",
            force: {
              layoutAnimation: false,
              repulsion: 1000,
            },
            // focusNodeAdjacency: false,
            //symbolSize: 65,
            // force: {
            //   layoutAnimation: "false",
            //   initLayout: "circular"
            // },
            roam: true,
            //categories:this.ca,
            lineStyle: {
              // 关系边的公用线条样式。其中 lineStyle.color 支持设置为'source'或者'target'特殊值，此时边会自动取源节点或目标节点的颜色作为自己的颜色。
              normal: {
                color: "#aaa", // 线的颜色[ default: '#aaa' ]
                width: 10, // 线宽[ default: 1 ]
                type: "solid", // 线的类型[ default: solid ]   'dashed'    'dotted'
                opacity: 1, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。[ default: 0.5 ]
                curveness: 1, // 边的曲度，支持从 0 到 1 的值，值越大曲度越大。[ default: 0 ]
              },
            },
            categories: [
              {
                name: "全部行业",
                itemStyle: {
                  label: {
                    show: true,
                  },
                  normal: {
                    color: "#391BDA",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 80,
              },
              {
                name: "一级分类",
                // symbol: "roundRect",
                itemStyle: {
                  normal: {
                    color: "#DA1B73",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 50,
              },
              {
                name: "三级分类",
                itemStyle: {
                  normal: {
                    color: "#00CED1",
                  },
                  nodeStyle: {
                    brushType: "both",

                    borderWidth: 1,
                  },
                },
                symbolSize: 50,
              },
              {
                name: "上游产业",
                symbol: "roundRect",
                itemStyle: {
                  normal: {
                    color: "#4B0082",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 50,
              },
              {
                name: "下游产业",
                itemStyle: {
                  normal: {
                    color: "#EA7CCC",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 50,
              },
              {
                name: "子公司",
                itemStyle: {
                  normal: {
                    color: "#FC8452",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 50,
              },
            ],
            label: {
              normal: {
                show: true,
                //position:'top',
                textStyle: {
                  fontSize: 12,
                  // color: "black"
                },
              },
            },
            edgeSymbol: ["none", "arrow"],
            edgeSymbolSize: [10, 7],
            edgeLabel: {
              normal: {
                show: true,
                textStyle: {
                  fontSize: 13,
                },
                formatter: "{c}",
              },
            },
            // data: graph_info["欣旺达"].data.nodes,
            data: this.graphData,
            // links: graph_info["欣旺达"].data.edge,
            links: this.graphLink,
            lineStyle: {
              normal: {
                opacity: 0.9,
                width: 1,
                curveness: 0,
              },
            },
          },
        ],
      };
      this.graphChart.setOption(option);
    },
  },
  mounted() {
    this.setOriginGraphChart();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.industryGraphEchartsTotal {
  .graphContainer {
    width: 96%;
    margin-left: 2%;
    height: 530px !important;
  }
  .graphChart {
    width: 100%;
    // height: 30vw;
    height: 100%;
    margin-top: 5px;
  }
}
</style>
